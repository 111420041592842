import cn from 'classnames';
import { ACCOUNT_TABS } from '@constants';
import { Button, YellowStar, YotpoWidget } from '@components';
import styles from './YotpoActivityWidgetBanner.module.scss';
import { useTranslation } from '@utils/index';

type YotpoActivityWidgetBannerProps = {
	widgetId: string;
	tab?: string;
	isHomePage?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		exploreAllTops: translator('explore-all-tops'),
	};
};

const YotpoActivityWidgetBanner = ({ widgetId, tab, isHomePage = false }: YotpoActivityWidgetBannerProps) => {
	const isMyRewardsTab = !isHomePage && tab === ACCOUNT_TABS.MY_REWARDS;
	const isMyCollectionsTab = tab === ACCOUNT_TABS.MY_COLLECTION;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const classNames = cn(styles.banner, {
		[styles.homePageReward]: isHomePage,
		[styles.isMyRewardsTab]: isMyRewardsTab,
		[styles.isMyCollectionsTab]: isMyCollectionsTab,
	});

	return (
		<div className={classNames}>
			<YellowStar />
			<div>
				<YotpoWidget widgetId={widgetId} />
			</div>
			{tab === ACCOUNT_TABS.MY_COLLECTION && (
				<Button href='/top-frames' label={translations.exploreAllTops} color='blue' size='small' />
			)}
		</div>
	);
};

export default YotpoActivityWidgetBanner;
